import { useContext, useEffect } from 'react';
import { PageContext } from '../contexts';

export function useMembershipPromoNavButton() {
  const { adsDisabled, isSponsored, membershipAvailable } = useContext(PageContext);

  const onEventMessage = (eventMessage) => {
    if (eventMessage?.data?.type === 'topic-nav-loaded' && !adsDisabled && !isSponsored && membershipAvailable) {
      window.postMessage({ type: 'show-membership-promo-button' }, '*');
    }
  };

  useEffect(() => {
    window.addEventListener('message', onEventMessage);

    return () => {
      window.removeEventListener('message', onEventMessage);
    };
  }, []);
}
