import React, { useContext } from 'react';
import ABeagleProvider from '../ABeagleProvider';
import ConsentManagement from '../ConsentManagement';
import GoogleTagManager from '../GoogleTagManager';
import TimeSpentTrackingConsumer from '../TimeSpentTrackingConsumer';
import StickyManager from '../StickyManager';
import {
  ConsentProvider,
  TrackingContext,
} from '../../contexts';
import {
  useAddressabilityTracking,
  usePageviewTracking,
  usePerformanceTracking,
  useComscoreTracking,
  useNavTracking,
} from '../../hooks';
import { useConsent } from '@buzzfeed/react-components';
import { gtm_enabled } from '../../constants';

/**
 * Wrapper that can be used for feed pages that provides some standardized tracking
 *  If used, should not include any of this tracking additionally.
 */
export function FeedPageWrapper({ children, userGeo }) {
  const { consentValue, isConsentKnown, isConsentReady } = useConsent('tracking');
  const gtmEnabled = gtm_enabled && userGeo !== 'US';

  const {
    context_page_id,
    context_page_type,
    destination,
    page_edition
  } = useContext(TrackingContext);

  // these various hooks, except performance tracking will pull appropriate page info from context
  //  within it.
  useAddressabilityTracking();
  usePageviewTracking({});
  useComscoreTracking();
  useNavTracking();
  usePerformanceTracking({
    context_page_id,
    context_page_type,
    destination,
    page_edition
  });

  return (
    <ConsentProvider value={{ tracking: { consentValue, isConsentKnown, isConsentReady } }}>
      <ABeagleProvider>
        <TimeSpentTrackingConsumer />
        <ConsentManagement />
        {gtmEnabled && <GoogleTagManager />}
        <StickyManager>
          {children}
        </StickyManager>
      </ABeagleProvider>
    </ConsentProvider>
  );
}

export default FeedPageWrapper;
